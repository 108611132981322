<template>
  <OrdersList />
</template>

<script>
  export default {
    components: {
      OrdersList: () => import('@/components/comercial/orders/OrdersList')
    }
  }
</script>